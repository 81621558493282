.part-form-flex {
  display: flex;
}

.part-form-flex button {
  margin-left: 1em;
}

.long-column {
  grid-column: 1 / 3;
}

.long-column .MuiChip-root {
  margin: 0.5em 0.5em 0 0;
}

.part-form-flex .MuiTextField-root {
  width: 10em;
}

.MuiAutocomplete-root .MuiInputBase-root {
  padding: 0 !important
}