.file-upload-container img {
  height: 50px;
  width: 50px;
  object-fit: cover;
  margin-right: 1em;
}

.file-upload-container {
  display: flex;
  align-items: center;
  padding-top: 1em;
}
