.footer-container {
  display: flex;
  justify-content: space-around;
  padding: 4em 0;
  background-color: rgb(19, 19, 19);
  color: #fff;
}

.footer-container h1 {
  margin: 0 0 2em 0;
}

.footer-container a {
  text-decoration: none;
  color: gray;
}

.footer-container p {
  margin: 0.6em 0;
}

.section-header {
  color: #fff;
}

@media (max-width: 700px) {
  .footer-container {
    flex-direction: column;
    align-items: center;
  }

  .footer-container h1 {
    text-align: center;
  }

  .footer-container .section {
    margin: 1em 0;
  }

  .footer-container p {
    text-align: center;
  }
}
