.title-grid,
.numbers-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.in-progress {
  background-color: red;
  padding: 1em;
  color: #fff;
}

.ready {
  background-color: rgb(0, 168, 82);
  padding: 1em;
  color: #fff;
}

.order-container {
  font-size: 4em;
  margin: 0.1em 0 0.1em 0.5em;
  font-weight: 800;
}

.gray {
  color: red;
}

.red {
  color: rgb(0, 168, 82);
}

.list-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(6, 1fr);
  grid-auto-flow: column;
}
