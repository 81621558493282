.login-page-wrapper {
  display: flex;
  min-height: 100vh;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.login-form-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2em;
  border-radius: 0.5em;
}

.login-form-wrapper .MuiTextField-root {
  margin-bottom: 1em;
}

.login-form-image {
  width: 5em;
  margin-bottom: 2em;
  border-radius: 0.5em;
}
